<template>
  <el-card>
    <div class="title-biaoti">Hostess</div>
    <el-row class="search">
      <el-button type="primary" @click="getHostListData()" :loading="deleteBun">Refresh</el-button>
    </el-row>
    <el-row>
      <el-table :data="hostData" style="width: 100%" v-loading="deleteBun" height="70vh" stripe border
        element-loading-text="loading" element-loading-spinner="el-icon-loading">
        <el-table-column prop="agentId" label="Agency ID"></el-table-column>
        <el-table-column prop="id" label="Hostesses ID"></el-table-column>
        <el-table-column prop="verifyStatus" label="Verify"></el-table-column>
        <el-table-column prop="headIcon" label="Avantar">
          <template slot-scope="scope">
            <el-image v-if="scope.row.headIcon !== ''" style="width: 100px; height: 100px" :src="scope.row.headIcon"
              :preview-src-list="[scope.row.headIcon]"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="gender" label="Gender"></el-table-column>
        <el-table-column prop="aristocracy" label="Aristocracy"></el-table-column>
        <el-table-column prop="email" label="E-mail"></el-table-column>
        <el-table-column prop="hostLevel" label="Level"></el-table-column>
        <el-table-column prop="status" label="Status"></el-table-column>

        <el-table-column prop="registerDate" label="Register date"></el-table-column>
        <el-table-column prop="lastLoginTime" label="Last Online time"></el-table-column>
        <el-table-column prop="totalDiamondIncome" label="Total diamond income">
          <template slot-scope="scope">
            {{ $store.getters.getCurDiamondNum(scope.row.totalDiamondIncome) }}
          </template>
        </el-table-column>
        <el-table-column prop="incomeBalance" label="Income Balance">
          <template slot-scope="scope">
            {{ $store.getters.getCurDiamondNum(scope.row.incomeBalance) }}
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row style="overflow: auto">
      <el-pagination @current-change="handleCurrentChangepat" :current-page="page" :page-size="30"
        layout="total,prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-row>

    <el-dialog title="Add Hostesses" :visible.sync="dialogVisible" :width="clientWidth == 'xs' ? '100%' : '50%'">
      <el-row :gutter="10">
        <el-col :span="6" class="newcss">Hostesses ID：</el-col>
        <el-col :span="12"><el-input placeholder="Input ID" v-model="hostId" clearable>
          </el-input></el-col></el-row>
      <el-row :gutter="10">
        <el-col :span="6" class="newcss">Nation：</el-col>
        <el-col :span="12">
          <el-select v-model="countryListIndex" placeholder="" filterable>
            <el-option v-for="(item, itemIndex) in countryList" :key="itemIndex" :label="item.country"
              :value="itemIndex"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6" class="newcss">Email that girl use it sgn in Kissboy：</el-col>
        <el-col :span="18">
          <el-input placeholder="" v-model="gMail" clearable @blur="googlelur()">
          </el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="clickButtn">Confirm</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  getHostList,
  getCountryList,
  getBindHost,
  getUntieHost
} from '@/api/operate.js'
import { bootstrapResponsive } from '@/utils/bootstrapResponsive.js'

export default {
  data() {
    return {
      deleteBun: false,
      hostData: [],
      countryList: [],
      dialogVisible: false,
      hostId: '',
      countryListIndex: '',
      gMail: '',
      // 分页
      page: 1,
      total: 0,
      // 验证输入内容
      rules: false,
      clientWidth: 'xs'
    }
  },
  created() {
    this.clientWidth = bootstrapResponsive()
    this.getHostListData()
    this._getCountryList()
  },
  methods: {
    // 分页
    handleCurrentChangepat(newpage) {
      this.page = newpage
      this.getHostListData()
    },
    // 获取国家码
    async _getCountryList() {
      const { data: res } = await getCountryList()
      this.countryList = res.data.countryList
    },

    // 获取主播列表
    async getHostListData() {
      const { data: res } = await getHostList({
        agentId: JSON.parse(sessionStorage.getItem('loginData')).agentId,
        page: this.page
      })
      if (res.code !== 0)
        return this.$message({
          message: res.msg,
          center: true,
          type: 'error'
        })
      this.$message({
        message: res.msg,
        center: true,
        type: 'success'
      })
      this.hostData = res.data.data
      this.total = res.data.total
    },
    // 验证谷歌邮箱
    googlelur() {
      // let str = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      // if (!str.test(this.gMail)) {
      //   this.$message.error('Please enter the correct email address')
      // } else {
      //   this.rules = true
      // }
    },
    // 提交添加
    async clickButtn() {
      if (
        this.countryListIndex == '' ||
        this.gMail == '' ||
        this.hostId == ''
      ) {
        this.$message({
          message: 'Please complete',
          center: true,
          type: 'error'
        })
        return
      }
      const { data: res } = await getBindHost({
        hostId: this.hostId,
        country: this.countryList[this.countryListIndex].country,
        countryCode: this.countryList[this.countryListIndex].code,
        agentId: JSON.parse(sessionStorage.getItem('loginData')).agentId,
        gMail: this.gMail
      })
      if (res.code !== 0)
        return this.$message({
          message: res.msg,
          center: true,
          type: 'error'
        })
      this.$message({
        message: res.msg,
        center: true,
        type: 'success'
      })
      this.getHostListData()
      this.dialogVisible = false
      this.countryListIndex = this.gMail = this.hostId = ''
    },
    // 解绑操作
    async handleClick(row) {
      const { data: res } = await getUntieHost({
        agentId: JSON.parse(sessionStorage.getItem('loginData')).agentId,
        rid: row.id
      })
      if (res.code !== 0)
        return this.$message({
          message: res.msg,
          center: true,
          type: 'error'
        })
      this.$message({
        message: res.msg,
        center: true,
        type: 'success'
      })
      this.getHostListData()
    }
  }
}
</script>

<style lang="scss" scoped>
.agent {
  min-height: 100%;
}

.host-name {
  margin-right: 15px;
}

.newcss {
  /* 这两个在技术上是一样的, 为了兼容了浏览器两个都加上 */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* 这个的使用在web-kit中有些危险，他可能会阶段所有东西 */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* 如果浏览器支持的话增加一个连接符(Blink不支持) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
</style>
