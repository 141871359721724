import request from '@/utils/request'

// 获取主播列表
export function getHostList(params) {
  return request({
    url: '/agent_admin/host/list',
    method: 'get',
    params
  })
}

// 获取国家码
export function getCountryList(params) {
  return request({
    url: '/agent_admin/country/list',
    method: 'get',
    params
  })
}

// 绑定主播
export function getBindHost(data) {
  return request({
    url: '/agent_admin/host/bind_host',
    method: 'post',
    data
  })
}

// 解绑主播
export function getUntieHost(params) {
  return request({
    url: '/agent_admin/host/untie_host',
    method: 'get',
    params
  })
}
